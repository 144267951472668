// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


// jQuery, jQuery UI
// Based on https://stackoverflow.com/questions/57555708/rails-6-how-to-add-jquery-ui-through-webpacker
require("jquery-ui")
require("jquery-ui-dist/jquery-ui.min.css")

// Import Bootstrap
// Followed https://blog.makersacademy.com/how-to-install-bootstrap-and-jquery-on-rails-6-da6e810c1b87
// but apparently only this one line is needed (the rest was already done above).
import "bootstrap";

// Import Font Awesome
// Based on hint from https://stackoverflow.com/questions/61794100/rails-webpack-fontawesome-how-to-stop-conversion-to-svg
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";


/*
 *****************************************************************************************
 * For Sphere template, v4.1.1 (21 April 2020)
 * https://wrapbootstrap.com/theme/sphere-resume-cv-bootstrap-4-WB00FD040
 *
 * Generally followed Alex Kee's instructions for integrating the template, from
 * https://rubyyagi.com/how-to-integrate-html-bootstrap-theme-into-rails-6/
 * But put custom theme stylesheets in the Sprockets asset pipeline.
 *****************************************************************************************
 */

require("easy-pie-chart/dist/jquery.easypiechart.min.js")
import "flexslider";
import "imagesloaded";
import "jquery-bridget"; // used when loading isotope-layout in main.js
import "isotope-layout";
import "jquery.scrollto";
import "masonry-layout";

import "../src/sphere/main"
import "../src/sphere/chart-themes/chart-theme-1"
import "../src/sphere/chart-themes/chart-theme-2"
import "../src/sphere/chart-themes/chart-theme-3"
import "../src/sphere/demo/style-switcher"
import "../src/sphere/plugins/back-to-top"

// Copy all static images under ../images and ../svg to the output folder,
// and you can reference them with <%= image_pack_tag 'media/img/abc.png' %> or <%= image_pack_tag 'media/svg/def.svg' %>
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// const svgs = require.context('../svg', true)
// const svgPath = (name) => svgs(name, true)

/*
 *****************************************************************************************
 * End Sphere template section
 *****************************************************************************************
 */


// Google Analytics
import "../src/google_analytics"
